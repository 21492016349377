import React, { Fragment } from "react";
import Layout from "../components/layout/layout";
import Fold from "../components/Fold/Fold";
import { TextPhotoSection } from "../components/TextPhotoSection/TextPhotoSection";
import { IntroHolder } from "../components/IntroHolder/IntroHolder";
import { OurWorkSection } from "../components/OurWorkSection/OurWorkSection";
import { graphql, useStaticQuery } from "gatsby";
import FadeDown from "../components/FadeDown/FadeDown";
import { IntroSection } from "../components/IntroSection/IntroSection";
import CtaSection from "../components/CtaSection/CtaSection";
import { LocationsSection } from "../components/LocationsSection/LocationsSection";
import { LocationCard } from "../components/LocationCard/LocationCard";

const IMAGE_QUERY = graphql`
  {
    engineer: file(
      relativePath: { eq: "about-us/everyone-is-an-engineer.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    efficientCommunication1: file(
      relativePath: { eq: "about-us/efficient-communication1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    efficientCommunication2: file(
      relativePath: { eq: "about-us/efficient-communication2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    peopleOverMethodology: file(
      relativePath: { eq: "about-us/people-over-methodology.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    dedicatedTeams1: file(
      relativePath: { eq: "about-us/dedicated-teams1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    dedicatedTeams2: file(
      relativePath: { eq: "about-us/dedicated-teams2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    peopleKickstage: file(
      relativePath: { eq: "about-us/designer-reading-lounge.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1340
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    imgLjuboAndPascal: file(relativePath: { eq: "about-us/ljubo-pascal.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ourWorkImage: file(relativePath: { eq: "our-work.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ourWorkImageMobile: file(
      relativePath: { eq: "about-us/our-work-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 768
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    zagrebImage: file(relativePath: { eq: "about-us/locations/zagreb.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 768
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    berlinImage: file(relativePath: { eq: "about-us/locations/berlin.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 768
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    aiImage: file(relativePath: { eq: "about-us/ai-innovation.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 768
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

const AboutUs = () => {
  const tempImage = useStaticQuery(IMAGE_QUERY);
  return (
    <Layout
      pageTitle="About"
      description="Kickstage was founded by an experienced duo of software developers and entrepreneurs from Germany and Croatia. With a combined experience of more than 20 years in the industry, we worked in corporations and small businesses alike. Wherever we were, we found true show-stoppers for the digital age. At Kickstage, we combined our learnings to build a company that focusses on passion over methodology and precision over processes."
    >
      <FadeDown fold>
        <Fold
          title={
            <Fragment>
              <span>About</span> Kickstage{" "}
            </Fragment>
          }
          description="We are a team of passionate engineers and designers, trusted by businesses worldwide to create their most mission-critical applications. Our company has been founded in 2011 by two industry professionals and has grown steadily since then. We are recognized as experts for real-time applications and open source technologies."
        />
        <IntroSection
          image={tempImage.peopleKickstage}
          title="We are a team of passionate engineers and designers, trusted by businesses worldwide to create their most mission-critical applications. Our company has been founded in 2011 by two industry professionals and has grown steadily since then. We are recognized as experts for real-time applications and open source technologies."
        />
      </FadeDown>

      <IntroHolder
        title={
          <Fragment>
            {" "}
            Our <span>Story</span>
          </Fragment>
        }
        level="h2"
        centered
      />

      <TextPhotoSection
        title="How it started"
        description="Kickstage was founded by an experienced duo of software developers and entrepreneurs from Germany and Croatia. With a combined experience of more than 20 years in the industry, we worked in corporations and small businesses alike. At Kickstage, we combined our learnings to build a company that focusses on passion over methodology and precision over processes."
        reverse
        smallText
        singleImage
        image={tempImage.imgLjuboAndPascal}
        imageRight
        radialmagesDecoration
        radialmagesDecorationColor="mandy"
        radialmagesDecorationPosition="bottom_right"
      />

      <FadeDown>
        <IntroHolder
          title={
            <Fragment>
              {" "}
              What it Takes to Build <br></br>{" "}
              <span>High Quality Software?</span>
            </Fragment>
          }
          level="h2"
          radialDecoration
          radialDecorationColor="yellow"
          radialDecorationPosition="introholder"
          centered
        />
      </FadeDown>
      <TextPhotoSection
        title={<Fragment>Everyone is an Engineer</Fragment>}
        description={
          <Fragment>
            We cut out the middleman — our clients will always talk directly to
            an engineer with a strong background in software development or
            another relevant field. This keeps the overhead low and allows us to
            constantly align the system design with the customer's requirements.
          </Fragment>
        }
        reverse
        smallText
        smallDescription
        smallPaddingTop
        singleImage
        image={tempImage.engineer}
        images_decoration
        imageRight
      />
      <TextPhotoSection
        title={<Fragment>Efficient Communication</Fragment>}
        description="Small details usually determine whether a project succeeds or fails. Inspired by spaceflight, we identified effective communication to be one of the crucial aspects and have developed tools and techniques to reduce friction everywhere."
        reverse
        smallText
        smallDescription
        multipleImages
        imagesTouching
        image={tempImage.efficientCommunication1}
        image2={tempImage.efficientCommunication2}
        smallPaddingTop
      />
      <FadeDown>
        <TextPhotoSection
          title={<Fragment>People Over Methodology</Fragment>}
          description="We live in a data-driven world and AI is slowly taking over repetitive tasks in all aspects of our lives, including software development. Yet, qualified people are more than ever the catalyst to successful projects. As a people-oriented firm, we support an autonomous workplace culture and inspire our teams to express their ideas and expertise — from architecture to methodology choices."
          smallText
          vertical
          image={tempImage.peopleOverMethodology}
        />
      </FadeDown>
      <TextPhotoSection
        title={<Fragment>Dedicated Teams</Fragment>}
        description="The constant switching of tasks and context that is found in many software companies can be detrimental not only to productivity but also to the quality of the output. To mitigate this, we have adopted a approach where we assign dedicated teams to handle each project from beginning to end, ensuring a focused and efficient workflow that yields high-quality results."
        reverse
        smallText
        smallDescription
        multipleImages
        image={tempImage.dedicatedTeams1}
        image2={tempImage.dedicatedTeams2}
        radialmagesDecoration
        radialmagesDecorationColor="mandy"
        radialmagesDecorationPosition="bottom_left"
      />

      <FadeDown>
        <TextPhotoSection
          title="Embracing Innovation with Responsibility"
          description="At Kickstage, we harness the power of AI to drive innovation, efficiency, and growth, guided by responsibility and a commitment to delivering real value."
          image={tempImage.aiImage}
          btnLink="/about-us/kickstage-ai-manifesto"
          btnText="Learn more about it"
          radialTextDecoration
          radialTextDecorationColor="yellow"
          radialTextDecorationPosition="bottom_left"
        />
      </FadeDown>

      <LocationsSection
        title={
          <Fragment>
            {" "}
            Our <span>Locations</span>
          </Fragment>
        }
      >
        <LocationCard
          title="Zagreb"
          description="Our Zagreb office on Radnicka Cesta is our main hub for
                development and design. With about 25 workspaces, it provides a
                collaborative environment for our team. The office also features
                areas for regular events, talks, and presentations, fostering
                innovation and knowledge sharing."
          image={tempImage.zagrebImage}
        />

        <LocationCard
          title="Berlin"
          description="Located in the historic Gendarmenmarkt, our compact Berlin office focuses on project management, concept work, and client meetings. Its central location ensures excellent reachability for both our team and clients, while providing a cozy atmosphere for strategic planning."
          image={tempImage.berlinImage}
        />
      </LocationsSection>

      <OurWorkSection
        title={
          <Fragment>
            <span>Our</span> Work
          </Fragment>
        }
        ctaText="See all projects"
        link="/our-work/"
        image={tempImage.ourWorkImage}
        mobileImage={tempImage.ourWorkImageMobile}
      />
      <CtaSection
        title="Let's Chat"
        label="What Can We Do For You?"
        link="/contact/"
      />
    </Layout>
  );
};

export default AboutUs;
