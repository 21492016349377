import React, { Fragment } from 'react';
import './location-section.scss';
import { IntroHolder } from '../IntroHolder/IntroHolder';


export const LocationsSection = ({ title, children }) => {
  return (
    <>
      <section className="location-section">
        <div className="container">
          <div className="gr-12 no-gutter@md">
            <div className="location-section__title-holder">
              <IntroHolder
                title={title}
                level="h2"
                centered
              />
            </div>
            <div className="location-section__wrap">
                {children}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};