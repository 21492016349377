import React from "react";
import "./location-card.scss";
import { Heading } from "../Heading/Heading";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const LocationCard = ({
  title,
  description,
  image,
}) => {
  const parsedImage = getImage(image);
  return (
    <>
      <div
        className="location-card"
        role="presentation"
      >
        <div className="location-card__wrap">
          <GatsbyImage
            image={parsedImage}
            className="image rspimg"
            alt={title}
          />
          <div className="location-card__content-holder">
            <Heading level="h3" decorationDown>{title}</Heading>
            <p className="description">{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};
